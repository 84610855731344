import { CriteoContent } from "~/types/crto"

export const criteoTag = {
  src: "https://dynamic.criteo.com/js/ld/ld.js?a=",
  async: true,
}

export const sha256 = async (_str: string) => {
  // Convert string to ArrayBuffer
  const buff = new Uint8Array([].map.call(_str, (c: string): number => c.charCodeAt(0)) as number[]).buffer
  // Calculate digest
  const digest = await crypto.subtle.digest("SHA-256", buff)
  // Convert ArrayBuffer to hex string
  // (from: https://stackoverflow.com/a/40031979)
  return [].map.call(new Uint8Array(digest), (x: number) => ("00" + x.toString(16)).slice(-2)).join("")
}

export const criteoPush = async (_type: string, _content: CriteoContent) => {
  const config = useRuntimeConfig()
  const CRITEO_PARTNER_ID = process.env.CRITEO_PARTNER_ID
  if (config.public.APP_ENV !== "production") return
  if (CRITEO_PARTNER_ID && _type && window.criteo_q) {
    window.criteo_q = window.criteo_q || []
    const innerWidth = window.innerWidth || 0
    const siteType = innerWidth <= 375 ? "m" : "d"
    if (_type === "productView") {
      // const hashEmail = await sha256(_content.email)
      window.criteo_q.push(
        { event: "setAccount", account: CRITEO_PARTNER_ID },
        { event: "setSiteType", type: siteType }, // モバイル"t" タブレット"d" 初期値、PC向け"
        // { event: "setEmail", email: hashEmail, hash_method: "sha256" }, // sha256 or none // guestの場合どうするか
        { event: "viewItem", item: _content.id },
      )
    }
    if (_type === "cv") {
      const hashEmail = await sha256(_content.email ? _content.email : "")
      window.criteo_q.push(
        { event: "setAccount", account: CRITEO_PARTNER_ID },
        { event: "setSiteType", type: siteType },
        { event: "setEmail", email: hashEmail, hash_method: "sha256" },
        { event: "trackTransaction", id: _content.id, item: _content.items }, //  [{ id: "商品ID1", price: 商品ID1の商品単価, quantity: 商品ID1の個数 } ... ],
      )
    }
  }
}
